import React, { useMemo, useState } from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link, Input, Button, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import axios from 'axios';

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const [journalJsonData, setJournalJsonData] = useState({
		journalName: '',
		journalTopic: '',
		emailId: '',
		phNo: '',
		coverPageUpload: null,
		articleUpload: null,
		photoUpload: ([]),
	});

	const [coverPageUploaded, setcoverPageUploaded] = useState(false);
	const [articleUploaded, setarticleUploaded] = useState(false);
	const [photoUploaded, setphotoUploaded] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	// const [journalName, setJournalName] = useState('');
	// const [journalTopic, setJournalTopic] = useState('');
	// const [emailId, setEmailId] = useState('');
	// const [phNo, setPhNo] = useState('');
	// const [coverPageUpload, setCoverPageUpload] = useState(null);
	// const [articleUpload, setArticleUpload] = useState(null);
	// const [photoUpload, setPhotoUpload] = useState([]);

	const { loginState, updateLoginState } = useLoginState(false);

	const handleJsonDataChange = (event) => {
		// const newValue = event.target.value; // Get the new value from the input
		// const eventTrigger = event.target.name;

		// switch (eventTrigger) {
		// 	case 'journalName':
		// 	    setJournalJsonData({ ...journalJsonData, journalName: newValue });
		// 		break;
		// 	case 'journalTopic':
		// 	    setJournalJsonData({ ...journalJsonData, journalTopic: newValue });
		// 	    break;
		// 	case 'emailId':
		// 	    setJournalJsonData({ ...journalJsonData, emailId: newValue });
		// 	    break;
		// 	case 'phNo':
		// 	    setJournalJsonData({ ...journalJsonData, phNo: newValue });
		// 	    break;
		// }
	};

	const handleFileUpload = (event) => {
		// console.log("\n###### File Upload Triggered ######\n");
		// const eventTrigger = event.target.name;
		// const selectedFile = Array.from(event.target.files[0]);

		// if ( eventTrigger == "coverPageUpload" ) {
		// 	console.log("\ncoverPageUpload\n");
		// 	setJournalJsonData({ ...journalJsonData, coverPageUpload: selectedFile });
		// 	setcoverPageUploaded(true);
		// 	console.log(journalJsonData.coverPageUpload);
		// }
		// else {
		// 	console.log("\narticleUpload\n");
		// 	setJournalJsonData({ ...journalJsonData, articleUpload: selectedFile });
		// 	setarticleUploaded(true);
		// 	console.log(journalJsonData.articleUpload);
		// }
	}	

	const handleImageUpload = (event) => {
		// const eventTrigger = event.target.name;
		// const selectedFiles = Array.from(event.target.files);

		// // Ensure the selected files are valid image types
		// const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff'];
		// const validFiles = selectedFiles.filter((file) => allowedFileTypes.includes(file.type));

		// if (validFiles.length > 5) {
		// 	setErrorMessage('You can only upload up to 5 image files.');
		// } 
		// else {
		// 	setErrorMessage('');
		// 	setJournalJsonData({ ...journalJsonData, photoUpload: validFiles });
		// 	setphotoUploaded(true);
		// }
	}

	function jsonToFormData() {

		// console.log("\n### Json data : ###\n");
		// console.log(JSON.stringify(journalJsonData));

		// const formData = new FormData();
		// for (const key in journalJsonData) {
		// 	if (journalJsonData.hasOwnProperty(key)) {
		// 		formData.append(key, journalJsonData[key]);
		// 	}
		// }
		// return formData;
	}

	const sendMail = async () => {
		// try {
		// 	const emailAdd = "NJ.SOMA.develop@gmail.com;editorinchiefsoma@gmail.com";
		// 	const mailSubject = "Article Submission - NJSOMA service";
		// 	const formattedData = Object.keys(journalJsonData)
		// 		.map(key => `${key}: ${journalJsonData[key]}`)
		// 		.join('\n')

		// 	console.log(formattedData);
		// 	const mailToLink = `mailto:${emailAdd}?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(formattedData)}`;
		// 	window.location.href = mailToLink;
		// } catch (error) {
		// }
	}

	const handleSubmit = async () => {		
		// console.log("Form submission.");
		// console.log(JSON.stringify(journalJsonData));
		// sendMail();

		//const formDataToSend = jsonToFormData();
		//console.log(JSON.stringify(formDataToSend));

		// formDataToSend.append('journalJsonData', JSON.stringify(journalJsonData));
		// console.log(formDataToSend.journalJsonData);
		// sendEmail(formDataToSend);

		// try {
		// 	// Send the data to the server
		// 	console.log("SendingEMail forward to server");
		// 	const response = await axios.post('./php_server/submitArticle.php', journalJsonData, {
		// 		//headers: { 'Content-Type': 'multipart/form-data' },
		// 		method: 'POST',
		// 	});

		// 	if (response.ok) {
		// 		const result = await response.text();
		// 		console.log("Article Submitted successfully");
		// 		console.log(result);
		// 	} else {
		// 		console.error('Article submission request failed. Please try again');
		// 		const result = await response.text();
		// 		console.log(result);
		// 	}

		// 	// Show a success message
		// 	alert('Article submission Data uploaded successfully!');
		// } catch (error) {
		// 	console.error('Error:', error);
		// 	console.log("Error in Article submission request detected");
		// 	alert('An error occurred while tring Article submission . Please try again later.');
		// }
	}

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"journal-submission"} />
		<Helmet>
			<title>
				Journal
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"journal-submission"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" quarkly-title="Form-7" xl-background="rgba(0, 0, 0, 0)">
			<Override slot="SectionContent" align-items="center" lg-align-items="flex-start" />
			<Box
				padding="40px 40px 100px 100px"
				background="--color-orange"
				border-radius="24px"
				// max-width="940px"
				width="100%"
				lg-max-width="480px"
				lg-padding="40px 40px 40px 40px"
			>
				<Override
					slot="Form"
					flex-direction="row"
					display="flex"
					width="100%"
					align-items="flex-end"
					justify-content="center"
				/>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead" align-self="center">
							<b>For submitting the article, please attach the 'Cover page' & 'Article' in word file and photograph 
							(1600 x 900 pixel or 300 DPI, png / jpeg / tiff format only), and email it to 
							'editorinchiefsoma@gmail.com' & 'admin@NJSOMA.societyofmedicalanatomists.com'</b><br/><br/>
							Please read <Link href="/instructions-for-authors/" color="#000000">Instructions for Author</Link> and  	
							<Link href="/ethical-guidelines/" color="#000000">Ethical Guidelines</Link> carefully before submitting your article. 
							Also Please add the following Information in the email - 
							<ul>Journal Name</ul>
							<ul>Article Topic</ul>
							<ul>Email ID</ul>
							<ul>Contact Number</ul>
						</Text>
					</Box>
				</Box>
				{/* <Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Journal name
						</Text>
						<Input
							border-radius="8px"
							padding="11px 16px 11px 16px"
							required={true}
							font="--lead"
							color="rgba(111, 131, 159, 0.5)"
							border-width="0px"
							placeholder-color="rgba(111, 131, 159, 0.5)"
							placeholder="Ventricle System"
							name="journalName"
							type="text"
							sm-width="100%"
							lg-width="100%"
							lg-margin="0px 0px 10px 0px"
							flex="1 1 auto"
							width="100%"
							margin="0px 0px 0px 0px"
        					onChange={handleJsonDataChange}
							value={journalJsonData.journalName}
						/>
					</Box>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Article Topic
						</Text>
						<Input
							border-radius="8px"
							padding="11px 16px 11px 16px"
							required={true}
							font="--lead"
							color="rgba(111, 131, 159, 0.5)"
							border-width="0px"
							placeholder-color="rgba(111, 131, 159, 0.5)"
							placeholder="Anatomy of ventricle"
							name="journalTopic"
							type="text"
							sm-width="100%"
							lg-width="100%"
							lg-margin="0px 0px 10px 0px"
							flex="1 1 auto"
							width="100%"
							margin="0px 0px 0px 0px"
        					onChange={handleJsonDataChange}
							value={journalJsonData.journalTopic}
						/>
					</Box>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Email ID
						</Text>
						<Input
							border-radius="8px"
							padding="11px 16px 11px 16px"
							required={true}
							font="--lead"
							color="rgba(111, 131, 159, 0.5)"
							border-width="0px"
							placeholder-color="rgba(111, 131, 159, 0.5)"
							placeholder="saurabh.sharma@gmail.com"
							name="emailId"
							type="text"
							sm-width="100%"
							lg-width="100%"
							lg-margin="0px 0px 10px 0px"
							flex="1 1 auto"
							width="100%"
							margin="0px 0px 0px 0px"
        					onChange={handleJsonDataChange}
							value={journalJsonData.emailId}
						/>
					</Box>
					<Box
						margin="0px 30px 0px 0px"
						sm-margin="0px 0px 30px 0px"
						lg-margin="0px 0px 30px 0px"
						md-width="100%"
						flex="1 1 auto"
						width="100%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Phone Number
						</Text>
						<Input
							color="rgba(111, 131, 159, 0.5)"
							lg-margin="0px 0px 10px 0px"
							border-width="0px"
							placeholder="xxxxx-xxxxx"
							flex="1 1 auto"
							name="phNo"
							required={true}
							width="100%"
							padding="11px 16px 11px 16px"
							type="email"
							lg-width="100%"
							placeholder-color="rgba(111, 131, 159, 0.5)"
							margin="0px 0px 0px 0px"
							font="--lead"
							sm-width="100%"
							border-radius="8px"
        					onChange={handleJsonDataChange}
							value={journalJsonData.phNo}
						/>
					</Box>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					width="100%"
					height="5%"
				>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Upload Cover Page (.doc, .docx files only)
						</Text>
						<Input
							type="file"
							accept=".docx, .doc"
							name="coverPageUpload"
							color="rgba(111, 131, 159, 0.5)"
							required={true}
							lg-margin="0px 0px 10px 0px"
							border-width="0px"
							width="100%"
							flex="1 1 auto"
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-dark"
							border-radius="8px"
							sm-display="block"
							sm-width="100%"
							onClick={handleFileUpload}
						/>
					</Box>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Upload Article (.doc, .docx files only)
						</Text>
						<Input
							type="file"
							accept=".docx, .doc"
							name="articleUpload"
							color="rgba(111, 131, 159, 0.5)"
							required={true}
							lg-margin="0px 0px 10px 0px"
							border-width="0px"
							width="100%"
							flex="1 1 auto"
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-dark"
							border-radius="8px"
							sm-display="block"
							sm-width="100%"
							onClick={handleFileUpload}
						/>
					</Box>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					width="100%"
					height="5%"
				>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
							Upload Photo ( jpeg, png, tiff file only, maximum 5 number of images )
						</Text>
						<Input
							type="file"
							multiple accept=".jpeg, .jpg, .png, .tiff"
							name="articleUpload"
							color="rgba(111, 131, 159, 0.5)"
							required={false}
							lg-margin="0px 0px 10px 0px"
							border-width="0px"
							width="100%"
							flex="1 1 auto"
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-dark"
							border-radius="8px"
							sm-display="block"
							sm-width="100%"
							onClick={handleImageUpload}
						/>
						{errorMessage && <p>{errorMessage}</p>}
					</Box>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					width="100%"
					height="5%"
				>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="60%"
						align="center"
					>
						<Button
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-dark"
							border-radius="8px"
							sm-display="block"
							sm-width="60%"
							align-self="center"
							width="30%"
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</Box>
				</Box> */}
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					opacity={0}
				>
					<Box
						margin="0px 30px 0px 0px"
						md-width="100%"
						display="flex"
						flex-direction="column"
						sm-margin="0px 0px 0px 0px"
						lg-margin="0px 0px 30px 0px"
						md-margin="0px 0px 30px 0px"
						flex="1 1 auto"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" color="--light" font="--lead" align-self="center">
							* Pending Review after submission
						</Text>
					</Box>
				</Box>
				<Box
					align-items="flex-end"
					md-align-items="center"
					display="flex"
					justify-content="center"
					lg-flex-wrap="wrap"
					lg-justify-content="flex-end"
					width="100%"
					height="5%"
				>
				</Box>
			</Box>
		</Section>
		<Section />
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});